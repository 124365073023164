import React from 'react';
import {
  designSystemToken,
  Headline,
  Icon,
  Body,
  Button,
} from '@lightricks/react-design-system';
import {IconName} from '@lightricks/react-design-system/dist/components/icon/iconNames';
import translate from '@/utils/translate';
import styles from './CampaignTypeOptions.module.scss';

const TRANSLATION_PREFIX = 'components.modal-new-campaign-wizard';

const CAMPAIGN_TYPES: {
  key: CampaignTypes;
  iconName: IconName;
  testID: string;
  titleLocaleLabelKey: string;
  descriptionLocaleLabelKey: string;
  points: string[];
}[] = [
  {
    key: 'brand-awareness',
    iconName: 'Navigation-Megaphone',
    testID: 'brand-awareness-option',
    titleLocaleLabelKey: `${TRANSLATION_PREFIX}.campaign-types.brand-awareness.title`,
    descriptionLocaleLabelKey: `${TRANSLATION_PREFIX}.campaign-types.brand-awareness.description`,
    points: [
      `${TRANSLATION_PREFIX}.campaign-types.brand-awareness.point1`,
      `${TRANSLATION_PREFIX}.campaign-types.brand-awareness.point2`,
    ],
  },
  {
    key: 'content-creation',
    iconName: 'Navigation-Photos-Options',
    testID: 'content-creation-option',
    titleLocaleLabelKey: `${TRANSLATION_PREFIX}.campaign-types.content-creation.title`,
    descriptionLocaleLabelKey: `${TRANSLATION_PREFIX}.campaign-types.content-creation.description`,
    points: [
      `${TRANSLATION_PREFIX}.campaign-types.content-creation.point1`,
      `${TRANSLATION_PREFIX}.campaign-types.content-creation.point2`,
    ],
  },
];

export const CAMPAIGN_TYPE: {[key: string]: string} = {
  BRAND_AWARENESS: 'brand-awareness',
  CONTENT_CREATION: 'content-creation',
} as const;

export type CampaignTypes =
  | typeof CAMPAIGN_TYPE.BRAND_AWARENESS
  | typeof CAMPAIGN_TYPE.CONTENT_CREATION
  | null;

type CampaignTypeOptionProps = {
  type: CampaignTypes;
  handleClick: (type: CampaignTypes) => void;
  iconName: IconName;
  titleLocaleLabelKey: string;
  descriptionLocaleLabelKey: string;
  points: string[];
  testID?: string;
};

function CampaignTypeOption(props: CampaignTypeOptionProps) {
  const {
    type,
    handleClick,
    iconName,
    titleLocaleLabelKey,
    descriptionLocaleLabelKey,
    points,
    testID = 'campaign-type-option',
  } = props;
  return (
    <div className={styles.campaignTypeOption}>
      <Icon size="large" appearance="neutral" name={iconName} />
      <Headline size="sm" color={designSystemToken('semantic.fg.primary')}>
        {translate(titleLocaleLabelKey)}
      </Headline>
      <div>
        <Body size="md" color={designSystemToken('semantic.fg.secondary')}>
          {translate(descriptionLocaleLabelKey)}
        </Body>
        <ul>
          {points.map((point) => (
            <li key={point}>
              <Body
                size="md"
                color={designSystemToken('semantic.fg.secondary')}
              >
                {translate(point)}
              </Body>
            </li>
          ))}
        </ul>
      </div>
      <Button
        testID={testID}
        className={styles.ctaButton}
        appearance="brand"
        mode="filled"
        size="medium"
        onClick={() => handleClick(type)}
      >
        {translate('components.modal-new-campaign-wizard.button')}
      </Button>
    </div>
  );
}

type CampaignTypeOptionsProps = {
  setCampaignType: (type: CampaignTypes) => void;
};

function CampaignTypeOptions(props: CampaignTypeOptionsProps) {
  const {setCampaignType} = props;
  return (
    <div className={styles.content}>
      <div className={styles.contentTitleContainer}>
        <Headline size="md" color={designSystemToken('semantic.fg.primary')}>
          {translate('components.modal-new-campaign-wizard.title')}
        </Headline>
        <Body size="md" color={designSystemToken('semantic.fg.secondary')}>
          {translate('components.modal-new-campaign-wizard.subtitle')}
        </Body>
      </div>
      <div className={styles.campaignTypeOptions}>
        {CAMPAIGN_TYPES.map((type) => (
          <CampaignTypeOption
            key={type.key}
            type={type.key}
            handleClick={setCampaignType}
            iconName={type.iconName}
            testID={type.testID}
            titleLocaleLabelKey={type.titleLocaleLabelKey}
            points={type.points}
            descriptionLocaleLabelKey={type.descriptionLocaleLabelKey}
          />
        ))}
      </div>

      <Body size="md" color={designSystemToken('semantic.fg.secondary')}>
        {translate('components.modal-new-campaign-wizard.bottom-text')}
      </Body>
    </div>
  );
}

export default CampaignTypeOptions;
