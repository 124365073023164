import React from 'react';
import {useSearchParams} from 'react-router-dom';
import {designSystemToken, Headline} from '@lightricks/react-design-system';
import {RouteProps} from '@/routes/RouteProps';
import AnalyticsService, {
  eventNames,
} from '@/services/analytics/AnalyticsService';
import {FLOW_NAMES} from '@/lib/analytics/analyticsConstants';
import translate from '@/utils/translate';
import {SUBSCRIPTION_HOME_ROUTES} from '@/config/subscriptionConstants';
import {SubscriptionVariant} from '@/types/subscription';
import PoppaysLoader from '@/components/PoppaysLoader';
import ActionCard from '@/components/action-card';
import Conditional from '@/components/conditional';
import SuggestedCreators from '@/components/suggested-creators';
import SuggestedCreatorsOld from '@/components/suggested-creators-old';
import useBrandCampaignsQuery from '@/hooks/queries/use-brand-campaigns-query';
import {ACTIVE_STATES} from '@/hooks/queries/use-brand-campaigns-query/useBrandCampaignsQuery';
import useSubscriptionQuery from '@/hooks/queries/use-subscription-query';
import useBrandId from '@/hooks/use-brand-id';
import useIsMobile from '@/hooks/use-is-mobile';
import useNavigation from '@/hooks/use-navigation';
import useCampaignSuggestedCreatorsStore from '@/stores/campaignSuggestedCreatorsStore';
import styles from './Home.module.scss';
import WorkflowCard from './components/workflow-card';
import useHomepageWorkflows from './useHomepageWorkflows';

const SUGGESTED_CREATORS_NEW_PARAM = 'suggestedCreatorsV2';

export default function Home(props: RouteProps) {
  const {testID = 'home', dashboardUser, flags = {}} = props;
  const isMobile = useIsMobile();
  const [searchParams, setSearchParams] = useSearchParams();
  const brandId = useBrandId();
  const {subscription} = useSubscriptionQuery({brandId});
  const navigation = useNavigation();
  const workflows = useHomepageWorkflows();
  const {aiStudio} = flags;
  const {selectedCampaignNetwork} = useCampaignSuggestedCreatorsStore();
  const showNewSuggestedCreators = searchParams.has(
    SUGGESTED_CREATORS_NEW_PARAM
  );

  const {brandCampaigns, isLoading: isLoadingBrandCampaigns} =
    useBrandCampaignsQuery({
      brandId,
      refetchOnWindowFocus: false,
      states: ACTIVE_STATES,
      cacheTime: Infinity,
      staleTime: Infinity,
      orderByLatest: true,
    });

  React.useEffect(
    function useRedirectToSubscriptionHome() {
      const subscriptionHomeRoute =
        SUBSCRIPTION_HOME_ROUTES[
          subscription.subscriptionType as SubscriptionVariant
        ];
      if (subscriptionHomeRoute) {
        navigation.navigate(subscriptionHomeRoute);
      }
    },
    [navigation, subscription.subscriptionType]
  );

  const renderHome = () => {
    return (
      <section aria-label={translate('views.home.workflows.label')}>
        <div className={styles.workflowsContainer}>
          {workflows.map((workflow) => {
            if (workflow.title === 'AI Studio' && !aiStudio) {
              return null;
            }
            return <WorkflowCard key={workflow.title} {...workflow} />;
          })}
        </div>
      </section>
    );
  };

  const renderSmartMatchesHome = () => {
    const firstName =
      dashboardUser?.firstName ||
      (dashboardUser?.displayName || '').split(' ')[0] ||
      '';
    return (
      <div className={styles.homepageContainer}>
        <div className={styles.homepageContent}>
          <Headline size="xl">
            {translate('Welcome back')}
            {firstName ? `, ${firstName}` : ''}
          </Headline>
          <div className={styles.actionsContainer}>
            <ActionCard
              className={styles.actionCard}
              backgroundColor={designSystemToken('reference.purple.10')}
              title={translate('Start Campaign')}
              subtitle={translate('Define your project details and budget')}
              onCtaClick={() => {
                searchParams.set('showNewCampaignWizardModal', 'true');
                setSearchParams(searchParams);
                const newCampaignCreationFlow = AnalyticsService.createFlow(
                  FLOW_NAMES.CAMPAIGN_CREATION
                );
                AnalyticsService.dispatchEvent(
                  eventNames.CAMPAIGN_CREATION_FLOW_STARTED,
                  {
                    campaign_creation_location: 'smart_matches_homepage',
                    campaign_creation_source: 'start_campaign_button',
                    flow_id: newCampaignCreationFlow.flow_id,
                    source_text: translate('Start Campaign'),
                    campaign_id: '',
                  }
                );
              }}
            />
            <ActionCard
              className={styles.actionCard}
              backgroundColor={designSystemToken('reference.mint.10')}
              title={translate('Search Creators')}
              subtitle={translate('Find creators for your project')}
              ctaLink="/search"
              onCtaClick={() => {
                const campaignCreationFlow = AnalyticsService.createFlow(
                  FLOW_NAMES.CAMPAIGN_CREATION
                );
                AnalyticsService.dispatchEvent(
                  eventNames.CAMPAIGN_CREATION_FLOW_STARTED,
                  {
                    campaign_creation_location: 'smart_matches_homepage',
                    campaign_creation_source: 'search_creators_button',
                    flow_id: campaignCreationFlow.flow_id,
                    source_text: translate('Search Creators'),
                    campaign_id: '',
                  }
                );
              }}
            />
          </div>
        </div>
        <Conditional
          condition={
            showNewSuggestedCreators &&
            ['instagram_business', 'tiktok_v2'].includes(
              selectedCampaignNetwork
            )
          }
          fallback={<SuggestedCreatorsOld />}
        >
          <SuggestedCreators />
        </Conditional>
      </div>
    );
  };

  if (isLoadingBrandCampaigns) {
    return (
      <div className={styles.container} data-testid={testID}>
        <PoppaysLoader />
      </div>
    );
  }

  return (
    <div className={styles.container} data-testid={testID}>
      <Conditional
        condition={brandCampaigns.length > 0 && !isMobile}
        fallback={renderHome()}
      >
        {renderSmartMatchesHome()}
      </Conditional>
    </div>
  );
}
