import React, {useState} from 'react';
import {designSystemToken, Label} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import Conditional from '@/components/conditional';
import Divider from '@/components/divider';
import useCreatorVettingReportStore, {
  creatorVettingReportActions,
  ReportSettings,
} from '@/stores/creatorVettingReportStore';
import {
  FailedFlagWithCategory,
  FlagWithCategory,
} from '../../hooks/use-get-vetting-categories/useGetVettingCategories';
import CREATOR_VETTING_REPORT_TEST_IDS from '../../testIds';
import ProfileVettingContentItemPosts from '../profile-vetting-content-item-posts';
import styles from './ProfileVettingContentItem.module.scss';

const TRANSLATION_PREFIX =
  'components.creator-vetting-report.components.profile-vetting-content-item';

function ContentItemHeader(props: {
  category: string;
  flag: string;
  report: ReportSettings;
  postsAmount: number;
  seeAll: boolean;
  setSeeAll: (seeAll: boolean) => void;
  showFlagName?: boolean;
  showTopDivider?: boolean;
}) {
  const {
    category,
    flag,
    report,
    postsAmount,
    seeAll,
    setSeeAll,
    showFlagName = true,
    showTopDivider = true,
  } = props;

  if (!showFlagName && !seeAll) {
    return null;
  }

  return (
    <div
      className={styles.contentItemHeader}
      data-testid={`${CREATOR_VETTING_REPORT_TEST_IDS.FLAGGED_CONTENT__HEADER}`}
    >
      {showTopDivider ? <Divider margin={0} /> : null}
      <div
        className={`${styles.category} ${showFlagName ? '' : styles.hidden}`}
      >
        <Label size="xs" color={designSystemToken('semantic.fg.primary')}>
          {category}
        </Label>
      </div>
      <div
        className={`${styles.flag} ${!showFlagName ? styles.smallPadding : ''}`}
      >
        <div
          className={`${styles.flagNameAndCount} ${
            showFlagName ? '' : styles.hidden
          }`}
        >
          <Label size="lg" color={designSystemToken('semantic.fg.primary')}>
            {flag}
          </Label>
          <Label size="lg" color={designSystemToken('semantic.fg.secondary')}>
            {`(${postsAmount})`}
          </Label>
        </div>
        <Conditional condition={seeAll}>
          <Label
            className={styles.seeLess}
            size="sm"
            color={designSystemToken('semantic.fg.secondary')}
            onClick={() => {
              setSeeAll(false);
              if (report?.id) {
                creatorVettingReportActions.setSeeAllFlagPosts(
                  report?.id,
                  flag,
                  false
                );
              }
            }}
          >
            {translate(`${TRANSLATION_PREFIX}.see-less`)}
          </Label>
        </Conditional>
      </div>
    </div>
  );
}

function ProfileVettingContentItem(props: {
  reportId: string;
  item: FlagWithCategory | FailedFlagWithCategory;
  isLast?: boolean;
  showFlagName?: boolean;
  showTopDivider?: boolean;
  onPostClick: (flag: string, postId: string) => void;
  trackAction: (actionName: string, actionValue: string) => void;
}) {
  const {
    reportId,
    item,
    isLast,
    showFlagName,
    showTopDivider,
    onPostClick,
    trackAction,
  } = props;
  const creatorVettingReportState = useCreatorVettingReportStore();
  const report = creatorVettingReportState.reports[reportId];
  const [seeAll, setSeeAll] = useState(
    report?.seeAllFlagPosts[item.id] || false
  );

  const handleSetSeeAll = (newSeeAll: boolean) => {
    setSeeAll(newSeeAll);
    trackAction(
      `See all ${item.category.name} => ${item.name} posts`,
      newSeeAll ? 'true' : 'false'
    );
  };

  return (
    <div className={styles.profileVettingContentItemContainer}>
      <ContentItemHeader
        category={item.category.name}
        flag={item.name}
        report={report}
        postsAmount={item.postsAmount}
        seeAll={seeAll}
        setSeeAll={handleSetSeeAll}
        showFlagName={showFlagName}
        showTopDivider={showTopDivider}
      />
      <ProfileVettingContentItemPosts
        posts={item.posts}
        flag={item.id}
        report={report}
        seeAll={seeAll}
        setSeeAll={handleSetSeeAll}
        onPostClick={onPostClick}
      />
      {isLast ? <Divider margin={0} /> : null}
    </div>
  );
}

export default ProfileVettingContentItem;
