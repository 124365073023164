import dayjs from 'dayjs';
import {sortBy} from 'lodash';
import React, {useMemo} from 'react';
import {Headline, Tooltip} from '@lightricks/react-design-system';
import getChartLabelAbbreviation from '@/utils/getChartLabelAbbreviation';
import getPrecisePercentage from '@/utils/getPrecisePercentage';
import getTickValues from '@/utils/getTickValues';
import isNumber from '@/utils/isNumber';
import translate from '@/utils/translate';
import LEGACY_COLORS from '@/config/legacyColors';
import {SHORT_MONTH_YEAR_FORMAT} from '@/config/timeFormats';
import {Post} from '@/types/post';
import {SocialNetworkProvider} from '@/types/socialNetworkProvider';
import PoppaysLoader from '@/components/PoppaysLoader';
import ChartLayout from '@/components/chart-layout';
import LineChart from '@/components/charts/line-chart';
import Conditional from '@/components/conditional';
import defaultDataByMonth, {
  DataByMonth,
} from '@/components/creator-profile/components/identity-social-health/utils/defaultDataByMonth';
import defaultLineChartTooltipLabel from '@/components/creator-profile/components/identity-social-health/utils/defaultLineChartTooltipLabel';
import {
  ADDITIONAL_OPTIONS_BASE,
  getDefaultScaleX,
  getDefaultScaleY,
} from '@/components/creator-profile/constants/chartPresets';
import {SocialHealthChartProps} from '../../socialHealthChart';

const TRANSLATION_PREFIX =
  'components.creator-profile.identity-social-health.charts.followers-vs-engagement';

interface MonthlyData {
  averageFollowerCount: number | null;
  averageEngagementRate: number | null;
}

function getFilteredPosts(posts: Post[]) {
  if (!posts) {
    return [];
  }

  const postsWithData = posts.filter(
    (post) => !!post.cachedAccountFollowerCount
  );

  return sortBy(postsWithData, (post) => dayjs(post.createdAtDay).unix());
}

function getAverageFollowers(posts: Post[]) {
  if (!posts) {
    return null;
  }

  const totalFollowers = posts.reduce(
    (total, post) => total + post.cachedAccountFollowerCount,
    0
  );

  return totalFollowers / posts.length;
}

function getAverageEngagementRate(posts: Post[]) {
  if (!posts) {
    return null;
  }

  const totalEngagementRate = posts.reduce((total, post) => {
    const {likeCount, commentCount, cachedAccountFollowerCount} = post;
    if (cachedAccountFollowerCount === 0) {
      return total;
    }
    const rate =
      ((likeCount || 0) + (commentCount || 0)) / cachedAccountFollowerCount;

    return total + rate;
  }, 0);

  const rawAverageEngagementRate = totalEngagementRate / posts.length;
  const roundedEngagementRate = Number(rawAverageEngagementRate.toFixed(4));
  const engagementRateAsPercent = roundedEngagementRate * 100;

  return engagementRateAsPercent;
}

function FollowersCountVsEngagementRateChart(props: SocialHealthChartProps) {
  const {posts, isLoadingPosts, identity} = props;

  const filteredPosts = useMemo(() => getFilteredPosts(posts), [posts]);

  const dataByMonth: DataByMonth<MonthlyData>[] = useMemo(
    () =>
      defaultDataByMonth(filteredPosts, (postsForMonth) => ({
        averageFollowerCount: getAverageFollowers(postsForMonth),
        averageEngagementRate: getAverageEngagementRate(postsForMonth),
      })),
    [filteredPosts]
  );

  const averageFollowersData = dataByMonth.map(
    ({data: {averageFollowerCount}}) => averageFollowerCount
  );
  const averageEngagementRatesData = dataByMonth.map(
    ({data: {averageEngagementRate}}) => averageEngagementRate
  );

  const months = dataByMonth.map(({month}) => month);
  const followersWithOutNulls = averageFollowersData.filter(isNumber);
  const engagementRateWithoutNulls =
    averageEngagementRatesData.filter(isNumber);
  const followerTickValues = getTickValues(followersWithOutNulls);
  let engagementRateTickValues = getTickValues(engagementRateWithoutNulls);

  if (
    engagementRateTickValues.min === 0 &&
    engagementRateTickValues.max === 0
  ) {
    engagementRateTickValues = {
      min: -1,
      max: 1,
    };
  }

  const getLabelByProvider = (
    labelName: 'FOLLOWERS' | 'ENG_RATE',
    provider: SocialNetworkProvider
  ) => {
    switch (labelName) {
      case 'FOLLOWERS':
        if (provider === 'youtube') {
          return translate('Subscribers');
        }
        return translate('Followers');
      case 'ENG_RATE':
        if (provider === 'youtube') {
          return translate('Popular Pays Average Engagement');
        }
        return translate('Engagement Rate');
      default:
        return translate('Followers');
    }
  };

  const getChartTitleByProvider = (provider: SocialNetworkProvider) => {
    if (provider === 'youtube') {
      return (
        <div style={{display: 'flex', gap: '4px'}}>
          <Headline size="xxs">{translate('Subscribers Counts vs')}</Headline>
          <Tooltip
            arrow
            title={translate(
              'Popular Pays has calculated this percentage based on the combination of likes and comments per subscriber.'
            )}
            sx={{'.MuiTooltip-tooltip': {minWidth: 368}}}
          >
            <div>
              <Headline size="xxs">
                {translate('Popular Pays Average Engagement')}
              </Headline>
            </div>
          </Tooltip>
        </div>
      );
    }

    return translate('Followers Counts vs Engagement Rate');
  };

  const chart = (
    <Conditional
      condition={!isLoadingPosts}
      fallback={<PoppaysLoader absolute />}
    >
      <LineChart
        datasets={[
          {
            label: getLabelByProvider(
              'FOLLOWERS',
              identity.provider as SocialNetworkProvider
            ),
            fill: false,
            data: averageFollowersData,
            yAxisID: 'y1',
            backgroundColor: LEGACY_COLORS.BLUE,
            borderColor: LEGACY_COLORS.BLUE,
            borderWidth: 2,
            cubicInterpolationMode: 'monotone',
          },
          {
            label: getLabelByProvider(
              'ENG_RATE',
              identity.provider as SocialNetworkProvider
            ),
            fill: false,
            data: averageEngagementRatesData,
            yAxisID: 'y2',
            backgroundColor: LEGACY_COLORS.YELLOW,
            borderColor: LEGACY_COLORS.YELLOW,
            borderWidth: 2,
            cubicInterpolationMode: 'monotone',
          },
        ]}
        additionalOptions={{
          ...ADDITIONAL_OPTIONS_BASE,
          scales: {
            x: getDefaultScaleX(months, SHORT_MONTH_YEAR_FORMAT),
            y1: getDefaultScaleY(
              getLabelByProvider(
                'FOLLOWERS',
                identity.provider as SocialNetworkProvider
              ),
              followerTickValues,
              (label) => getChartLabelAbbreviation(label as number),
              false,
              'left'
            ),
            y2: getDefaultScaleY(
              getLabelByProvider(
                'ENG_RATE',
                identity.provider as SocialNetworkProvider
              ),
              engagementRateTickValues,
              (label) => getPrecisePercentage(label as number),
              false,
              'right'
            ),
          },
          plugins: {
            legend: {
              display: true,
              position: 'top',
            },
            tooltip: {
              intersect: false,
              callbacks: {
                label: defaultLineChartTooltipLabel,
              },
            },
          },
        }}
        labels={months}
      />
    </Conditional>
  );

  return (
    <ChartLayout
      title={getChartTitleByProvider(
        identity.provider as SocialNetworkProvider
      )}
      chart={chart}
      showEmptyState={filteredPosts.length === 0 && !isLoadingPosts}
    />
  );
}

export default FollowersCountVsEngagementRateChart;
