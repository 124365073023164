import React, {useEffect, useRef, useState} from 'react';
import {Body, Icon, Label} from '@lightricks/react-design-system';
import Arrow, {ArrowDirection} from '@/components/arrow/Arrow';
import Conditional from '@/components/conditional';
import Skeleton from '@/components/skeleton';
import styles from './ItemsBar.module.scss';
import ItemAvatar from './components/item-avatar';

interface Item {
  id: string;
  label: string;
  imageUrl: string;
}

interface ItemsBarProps {
  isLoading: boolean;
  containerWidth: number;
  items: Item[];
  onItemClick: (itemId: string) => void;
  selectedItemId: string;
  itemMinWidth?: number;
  itemMaxWidth?: number;
  arrowWidth?: number;
  itemGap?: number;
  maxSkeletons?: number;
}

function ItemSkeleton() {
  return (
    <div className={styles.itemAvatarContainer}>
      <Skeleton width={52} height={52} variant="circular" />
      <Skeleton width={50} height={14} variant="rectangular" />
    </div>
  );
}

function ItemsBar(props: ItemsBarProps) {
  const {
    isLoading,
    containerWidth,
    items,
    onItemClick,
    selectedItemId,
    itemMinWidth = 80,
    itemMaxWidth = 104,
    arrowWidth = 48,
    itemGap = 16,
    maxSkeletons = 10,
  } = props;

  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);

  // Calculate items per page based on container width
  useEffect(() => {
    const availableWidth = containerWidth - (arrowWidth + itemGap * 2) * 2;
    const maxItems = Math.floor(availableWidth / (itemMinWidth + itemGap));
    setItemsPerPage(maxItems);
  }, [containerWidth, itemMinWidth, arrowWidth, itemGap]);

  // Set CSS variables for responsive widths
  const cssVariables = {
    '--items-bar-min-item-width': `${itemMinWidth}px`,
    '--items-bar-max-item-width': `${itemMaxWidth}px`,
    '--items-bar-item-gap': `${itemGap}px`,
  } as React.CSSProperties;

  useEffect(() => {
    // find the page in which the item is at and scroll to it
    const itemIndex = items.findIndex((item) => item.id === selectedItemId);
    if (itemIndex !== -1) {
      const itemPage = Math.floor(itemIndex / itemsPerPage);
      setCurrentPage(itemPage);
    }
  }, [selectedItemId, items, itemsPerPage]);

  const totalPages = Math.ceil(items.length / itemsPerPage);
  const canScrollLeft = currentPage > 0;
  const canScrollRight = currentPage < totalPages - 1;

  const onArrowClick = (direction: ArrowDirection) => {
    if (direction === ArrowDirection.LEFT && canScrollLeft) {
      setCurrentPage((prev) => prev - 1);
    } else if (direction === ArrowDirection.RIGHT && canScrollRight) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  // Get current page items
  const getCurrentPageItems = () => {
    const start = currentPage * itemsPerPage;
    const end = start + itemsPerPage;
    return items.slice(start, end);
  };

  const renderContent = () => {
    if (isLoading) {
      return (
        <div className={`${styles.itemsContainer}`}>
          {[...Array(Math.min(maxSkeletons, itemsPerPage))].map((_, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <ItemSkeleton key={index} />
          ))}
        </div>
      );
    }

    return (
      <>
        <Conditional condition={canScrollLeft}>
          <Arrow
            buttonClassName={`${styles.arrow} ${styles.left} ${
              !canScrollLeft ? styles.hidden : ''
            }`}
            direction={ArrowDirection.LEFT}
            onClick={onArrowClick}
            renderIcon={() => (
              <Icon
                appearance="neutral"
                size="large"
                name="Actions-Arrow-Back-Small"
              />
            )}
          />
        </Conditional>
        <div className={styles.itemsContainer}>
          {getCurrentPageItems().map((item) => {
            const isSelected = selectedItemId === item.id;
            const NameElement = isSelected ? Label : Body;
            return (
              <div
                key={item.id}
                className={`${styles.itemAvatarContainer} ${
                  isSelected ? styles.selected : ''
                }`}
                onClick={() => onItemClick(item.id)}
              >
                <ItemAvatar
                  imageUrl={item.imageUrl}
                  label={item.label}
                  className={styles.avatar}
                />
                <NameElement size="xs" className={styles.itemName}>
                  {item.label}
                </NameElement>
              </div>
            );
          })}
        </div>
        <Conditional condition={canScrollRight}>
          <Arrow
            buttonClassName={`${styles.arrow} ${styles.right} ${
              !canScrollRight ? styles.hidden : ''
            }`}
            direction={ArrowDirection.RIGHT}
            onClick={onArrowClick}
            renderIcon={() => (
              <Icon
                appearance="neutral"
                size="large"
                name="Actions-Arrow-Back-Small"
              />
            )}
          />
        </Conditional>
      </>
    );
  };

  return (
    <div
      className={styles.itemsBarContainer}
      style={{maxWidth: containerWidth, ...cssVariables}}
      ref={containerRef}
    >
      {renderContent()}
    </div>
  );
}

export default ItemsBar;
