import copy from 'copy-to-clipboard';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import {v4 as uuid} from 'uuid';
import {useShallow} from 'zustand/react/shallow';
import {Headline, Icon} from '@lightricks/react-design-system';
import AnalyticsService, {
  eventNames,
} from '@/services/analytics/AnalyticsService';
import {FLOW_NAMES, SCREEN_NAMES} from '@/lib/analytics/analyticsConstants';
import raiseFlashMessage, {
  raiseFlashMessageError,
} from '@/utils/raiseFlashMessage';
import queryClient from '@/utils/reactQueryClient';
import translate from '@/utils/translate';
import PERMISSIONS from '@/config/permissions';
import {CampaignProps} from '@/types/campaign';
import {MembershipLabel} from '@/types/creatorProfile';
import {
  BaseFilterOption,
  FiltersGroup,
  SingleSelection,
} from '@/types/models/search-creators/filter';
import {
  FilterIds,
  FiltersGroupId,
} from '@/types/models/search-creators/filterId';
import {CreatorSearchResult} from '@/types/models/search-creators/searchCreators';
import {
  SearchCreatorsStateDefinition,
  ViewType,
} from '@/types/models/search-creators/searchCreatorsStore';
import SortField, {
  DefaultSortField,
} from '@/types/models/search-creators/sortField';
import {SortingState} from '@/types/models/search-creators/sorting';
import creatorsFetchers from '@/api/fetchers/creators';
import conversationsFetcher, {
  CurrentBrandCollaborationConversationResponse,
} from '@/api/fetchers/creators/conversations';
import ActionsDrawer from '@/views/creators/components/actions-drawer';
import CreatorAction from '@/views/creators/components/creator-action';
import {
  ActiveActionKeyAndCreators,
  CREATOR_ACTION_KEYS,
} from '@/views/creators/components/creator-action/CreatorAction';
import {CreatorProps} from '@/views/creators/components/creator-action/action/ActionProps';
import {ActionItem} from '@/views/creators/components/creators-actions-bar/ActionsBar';
import CreatorsActionsBar from '@/views/creators/components/creators-actions-bar/CreatorsActionsBar';
import NoActiveCampaignsModal from '@/views/creators/components/no-active-campaigns-modal';
import CampaignSelector from '@/views/creators/search/components/campaign-selector';
import SearchControls from '@/views/creators/search/components/search-controls';
import SearchCreatorsDisplay from '@/views/creators/search/components/search-creators-display';
import SearchTopBar from '@/views/creators/search/components/search-top-bar';
import useApplyCampaignFilters from '@/views/creators/search/hooks/use-apply-campaign-filters';
import useCreateFiltersStackFromCampaignData from '@/views/creators/search/hooks/use-create-filter-stack-from-campaign-data';
import useSortedCampaigns, {
  SimplifiedCampaign,
} from '@/views/creators/search/hooks/use-sorted-campaigns/useSortedCampaigns';
import getAnalyticsSearchScreenName from '@/views/creators/search/utils/getAnalyticsSearchScreenName';
import getObjectDiff from '@/views/creators/search/utils/getObjectDiff';
import getProfileImageUrl from '@/views/creators/search/utils/getProfileImageUrl';
import mapCreatorCardPropsForAction from '@/views/creators/search/utils/mapCreatorCardPropsForAction';
import mapCreatorSearchResultsForAction from '@/views/creators/search/utils/mapCreatorSearchResultsForAction';
import mapCreatorsForAnalytics from '@/views/creators/search/utils/mapCreatorsForAnalytics';
import {
  mapPersistenceStateToSearchCreatorsState,
  mapSearchCreatorsStateToPersistenceState,
} from '@/views/creators/search/utils/persistenceState';
import {
  CreatorCardCtaProps,
  CreatorCardProps,
} from '@/components/creator-card/CreatorCard';
import {CreatorMenuItemProps} from '@/components/creator-card/CreatorOverflowMenuButton';
import FlashMessage from '@/components/flash-message';
import FloatingPopperBar from '@/components/floating-popper-bar';
import Page from '@/components/page';
import useTrackRecruitmentActionFlowEvent from '@/hooks/analytics/use-track-recruitment-action-flow-event';
import useTrackSearchButtonEvent from '@/hooks/analytics/use-track-search-button-event';
import useTrackSearchScreenEvent from '@/hooks/analytics/use-track-search-screen-event';
import useRaiseCreatorInvitedToCampaignSuccess from '@/hooks/flash-messages/use-raise-creator-invited-to-campaign-success/useRaiseCreatorInvitedToCampaignSuccess';
import useInviteCreatorsToCampaign from '@/hooks/mutations/creators/use-invite-creators-to-campaign';
import useBrandCampaignsQuery from '@/hooks/queries/use-brand-campaigns-query';
import useBrandCreatorGroupsQuery from '@/hooks/queries/use-brand-creator-groups-query';
import useCampaignQuery from '@/hooks/queries/use-campaign-query';
import useCreatorGroupsLabelsQuery from '@/hooks/queries/use-creator-groups-labels-query';
import useCreatorProfileQuery from '@/hooks/queries/use-creator-profile-query';
import useCreatorsMetadataQuery from '@/hooks/queries/use-creators-metadata-query';
import useDashboardUserQuery from '@/hooks/queries/use-dashboard-user-query';
import useSubscriptionQuery from '@/hooks/queries/use-subscription-query';
import useBrandId from '@/hooks/use-brand-id';
import useDashboardUserPermissions from '@/hooks/use-dashboard-user-permissions';
import useNavigation from '@/hooks/use-navigation';
import usePrevious from '@/hooks/use-previous';
import useRaiseCreatorAddedToListSuccess from '@/hooks/use-raise-creators-added-to-list-success';
import useSearchCreatorsStore, {
  initialSearchCreatorsState,
  searchCreatorsActions,
  VIEW_TYPE_TABLE,
} from '@/stores/search-creators/searchCreatorsStore';
import styles from './Search.module.scss';

const TRANSLATION_PREFIX = 'views.creators';
const POPPER_HORIZONTAL_PADDING = 16;
const POPPER_MAX_WIDTH = 1100;
const POPPER_PLACEMENT_OFFSET = 24;
const PERSISTED_STATE_KEY = 'ps';
const AUTO_SELECT_CAMPAIGN_SESSION_KEY = 'autoSelectFirstCampaign';

function usePrefetchRequiredData(brandId: string) {
  const {
    creatorGroups,
    isLoading: isLoadingBrandCreatorGroup,
    isFetched: isFetchedBrandCreatorGroup,
  } = useBrandCreatorGroupsQuery({
    brandId,
    refetchOnWindowFocus: false,
  });
  const creatorGroupId = creatorGroups[0]?.id ?? '';
  const {
    labels,
    isLoading: isLoadingCreatorGroupsLabels,
    isFetched: isFetchedCreatorGroupsLabels,
  } = useCreatorGroupsLabelsQuery({
    creatorGroupId,
    refetchOnWindowFocus: false,
  });
  const {
    brandCampaigns,
    isLoading: isLoadingBrandCampaigns,
    isFetched: isFetchedBrandCampaigns,
  } = useBrandCampaignsQuery({
    brandId,
    refetchOnWindowFocus: false,
    states: ['accepting_proposals'],
  });

  return {
    creatorGroups,
    labels,
    brandCampaigns,
    isLoadingPrefetchedData:
      isLoadingBrandCreatorGroup ||
      isLoadingCreatorGroupsLabels ||
      isLoadingBrandCampaigns,
    isFetchedPrefetchedData:
      isFetchedBrandCreatorGroup &&
      isFetchedCreatorGroupsLabels &&
      isFetchedBrandCampaigns,
  };
}

function getPersistedStateFromSearchParams(
  searchParams: URLSearchParams
): SearchCreatorsStateDefinition | undefined {
  const persistedState = searchParams.get(PERSISTED_STATE_KEY) ?? '';
  if (!persistedState) return undefined;
  try {
    const decodedString = decodeURIComponent(escape(atob(persistedState)));
    return mapPersistenceStateToSearchCreatorsState(
      decodedString,
      initialSearchCreatorsState
    );
  } catch (e) {
    return undefined;
  }
}

function encodePersistedState(
  viewType: ViewType,
  searchQuery: string | undefined,
  filterGroups: Record<FiltersGroupId, FiltersGroup<FilterIds>>,
  sorting: SortingState | undefined,
  brandId: string,
  selectedCampaignId?: string
): string {
  const jsonString = JSON.stringify(
    mapSearchCreatorsStateToPersistenceState(
      viewType,
      searchQuery,
      filterGroups,
      sorting,
      brandId,
      selectedCampaignId
    ) ?? ''
  );
  return btoa(unescape(encodeURIComponent(jsonString)));
}

function Search() {
  const {
    data: metadata,
    isFetched: isMetadataFetched,
    isError: isMetadataError,
  } = useCreatorsMetadataQuery();
  const brandId = useBrandId();
  const {subscription} = useSubscriptionQuery({
    brandId,
  });
  const {dashboardUser} = useDashboardUserQuery({});
  const dashboardUserPermissions = useDashboardUserPermissions(
    [PERMISSIONS.COLLABORATIONS.CREATE],
    dashboardUser?.permissions
  );
  const raiseCreatorAddedToListSuccess = useRaiseCreatorAddedToListSuccess();
  const canCreateCollaboration =
    !subscription.isFree && dashboardUserPermissions.createCollaborations;

  const trackSearchButtonEvent = useTrackSearchButtonEvent();
  const trackSearchScreenEvent = useTrackSearchScreenEvent();
  const trackRecruitmentActionFlowEvent = useTrackRecruitmentActionFlowEvent();
  const {navigate} = useNavigation();

  const wasAlreadyAutoSelected = sessionStorage.getItem(
    AUTO_SELECT_CAMPAIGN_SESSION_KEY
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const creatorProfileIdRedirect = searchParams.get('creatorProfileIdRedirect');
  const previousSearchParams = usePrevious(
    new URLSearchParams(searchParams?.toString())
  );
  const hasPersistedStateInSearchParams = searchParams.has(PERSISTED_STATE_KEY);
  const [uniqueSelectActionTrigger, setUniqueSelectActionTrigger] =
    useState('');
  const [isRestoringFromPersistenceState, setIsRestoringFromPersistenceState] =
    useState(hasPersistedStateInSearchParams);
  const raiseCreatorInvitedToCampaignSuccess =
    useRaiseCreatorInvitedToCampaignSuccess();

  const [campaignId, setCampaignId] = useState<string | undefined>(undefined);
  const [creatorsInvitedStatus, setCreatorsInvitedStatus] = useState<
    {id: string; invited: boolean}[]
  >([]);

  useEffect(() => {
    const recruitmentSearchFlowExists = AnalyticsService.flowExists(
      FLOW_NAMES.RECRUITMENT_SEARCH
    );
    const recruitmentSearchFlow = AnalyticsService.getOrCreateFlow(
      FLOW_NAMES.RECRUITMENT_SEARCH
    );

    if (!recruitmentSearchFlowExists) {
      AnalyticsService.dispatchEvent(
        eventNames.RECRUITMENT_SEARCH_FLOW_STARTED,
        {
          flow_id: recruitmentSearchFlow.flow_id,
          screen_presentation_id: recruitmentSearchFlow.screen_presentation_id,
          recruitment_search_id: '',
          recruitment_screen_name: getAnalyticsSearchScreenName(viewType),
          campaign_id: '',
          num_results: '',
        }
      );
    }

    // return () => {
    //   AnalyticsService.dispatchEvent(eventNames.RECRUITMENT_SEARCH_FLOW_ENDED, {
    //     flow_id: recruitmentSearchFlow.flow_id,
    //     screen_presentation_id: recruitmentSearchFlow.screen_presentation_id,
    //     screen_name: '/search',
    //     tab: '',
    //     destination:
    //       `${window.location.pathname}${window.location.search}` || '',
    //   });
    // };
  }, []);

  const [
    viewType,
    openedFiltersGroup,
    filtersGroups,
    paging,
    sorting,
    selectedCreators,
    filtersGroupsSnapshot,
    searchQuery,
    sessionId,
    selectedCampaignId,
    selectedBrandId,
  ] = useSearchCreatorsStore(
    useShallow((state) => [
      state.viewType,
      state.content.filtersState.openedFiltersGroup,
      state.content.filtersState.filtersGroups,
      state.content.paging,
      state.content.sorting,
      state.content.selectedCreators,
      state.content.filtersState.filtersGroupsSnapshot,
      state.searchQuery,
      state.content.sessionId,
      state.selectedCampaignId,
      state.selectedBrandId,
    ])
  );

  const selectedPlatform = useMemo(
    () =>
      (
        filtersGroups.platforms.filters
          .platform as SingleSelection<BaseFilterOption>
      ).selected?.value,
    [filtersGroups]
  );

  const campaignFiltersAppliedFlashMessageRef = useRef<() => void | null>();

  useEffect(() => {
    if (selectedCampaignId && !wasAlreadyAutoSelected) {
      setCampaignId(selectedCampaignId);
    }
  }, [selectedCampaignId, wasAlreadyAutoSelected]);

  const {campaign} = useCampaignQuery({
    campaignId: campaignId ?? '',
    enabled: !!selectedCampaignId,
    refetchOnWindowFocus: false,
  });

  const selectedCampaign: CampaignProps | null = useMemo(
    () => (campaign.loaded ? campaign : null),
    // eslint-disable-next-line
    [selectedCampaignId, campaign.loaded, uniqueSelectActionTrigger]
  );

  const {brandCampaigns, isLoadingPrefetchedData} =
    usePrefetchRequiredData(brandId);
  const sortedCampaigns: SimplifiedCampaign[] =
    useSortedCampaigns(brandCampaigns);
  const campaignFiltersStack = useCreateFiltersStackFromCampaignData(
    selectedCampaign,
    uniqueSelectActionTrigger,
    isLoadingPrefetchedData
  );

  useApplyCampaignFilters(
    campaignFiltersStack,
    raiseSuccessCampaignFiltersSelected,
    isLoadingPrefetchedData
  );

  useEffect(() => {
    if (selectedBrandId && selectedBrandId !== brandId) {
      searchCreatorsActions.updateUserLastBrandId(brandId);
      searchCreatorsActions.updateUserSelectedCampaignId('');
      setCampaignId('');
      searchCreatorsActions.resetAllFilters(true);
      searchCreatorsActions.updateSearchQuery('');
      sessionStorage.removeItem(AUTO_SELECT_CAMPAIGN_SESSION_KEY);
      if (sortedCampaigns.length > 0) {
        setUniqueSelectActionTrigger(uuid());
      }
    }
  }, [selectedBrandId, brandId, sortedCampaigns.length]);

  /* Callbacks */
  const logActionDrawerButtonClick = useCallback(
    (label: string) => {
      const filterGroup = filtersGroups[openedFiltersGroup as FiltersGroupId];

      trackSearchButtonEvent.pressed({
        button_name: label,
        screen_name: getAnalyticsSearchScreenName(viewType),
        tab: translate(filterGroup.localeLabelKey),
      });
    },
    [filtersGroups, openedFiltersGroup, trackSearchButtonEvent]
  );

  const onPresentedOrDismissed = useCallback(
    (presentedOrDismissed: 'presented' | 'dismissed', name: string) => {
      if (presentedOrDismissed === 'presented') {
        trackSearchScreenEvent.presented({
          screen_name: getAnalyticsSearchScreenName(viewType),
          tab: name,
        });
      } else if (presentedOrDismissed === 'dismissed') {
        trackSearchScreenEvent.dismissed({
          screen_name: getAnalyticsSearchScreenName(viewType),
          tab: name,
        });
      }
    },
    [trackSearchScreenEvent]
  );

  /* ====== Local state ====== */
  const [showNoActiveCampaignsModal, setShowNoActiveCampaignsModal] =
    useState<boolean>(false);
  const [activeActionKeyAndCreators, setActiveActionKeyAndCreators] =
    useState<ActiveActionKeyAndCreators | null>(null);

  const getSingleCreatorProfileId = () => {
    if (activeActionKeyAndCreators?.creators.length === 1) {
      return activeActionKeyAndCreators?.creators[0].id;
    }
    if (selectedCreators.size === 1) {
      return [...selectedCreators.values()][0].id;
    }
    return null;
  };

  const {profile} = useCreatorProfileQuery({
    brandId,
    profileId: getSingleCreatorProfileId(),
    enabled: !!getSingleCreatorProfileId(),
    include: ['current_brand_membership.labels'],
  });

  const getCreatorActionInitState = () => {
    if (profile && profile.currentBrandMembership) {
      return {
        [CREATOR_ACTION_KEYS.ADD_TO_MY_CREATORS]:
          profile.currentBrandMembership.labels.map((label) => ({
            ...label,
            value: label.id,
            label: label.name,
            disabled: true,
          })),
      };
    }
    return undefined;
  };

  /* ====== Side effects ====== */

  // Overriding search params with the previous search params if persisted state is cleared by navigation
  useEffect(() => {
    if (
      !hasPersistedStateInSearchParams &&
      previousSearchParams?.has(PERSISTED_STATE_KEY)
    ) {
      searchParams.set(
        PERSISTED_STATE_KEY,
        previousSearchParams.get(PERSISTED_STATE_KEY)
      );
      setSearchParams(searchParams, {replace: true});
    }
  }, [
    hasPersistedStateInSearchParams,
    sortedCampaigns,
    selectedCampaignId,
    brandId,
  ]);

  useEffect(() => {
    if (hasPersistedStateInSearchParams) {
      const savedState = getPersistedStateFromSearchParams(searchParams);
      if (savedState) {
        searchCreatorsActions.initFromUrl({
          ...savedState,
          content: {
            ...savedState?.content,
            sessionId,
            selectedCreators,
            paging,
          },
        });
      }
    }
    setIsRestoringFromPersistenceState(false);
  }, []);

  useEffect(() => {
    // RECRUITMENT_SEARCH_DETAIL analytics event
    if (isRestoringFromPersistenceState) {
      return;
    }

    try {
      const currentPersistedState =
        searchParams?.get(PERSISTED_STATE_KEY) ?? '';
      const previousPersistedState =
        previousSearchParams?.get(PERSISTED_STATE_KEY) ?? '';
      if (
        currentPersistedState &&
        previousPersistedState &&
        currentPersistedState !== previousPersistedState
      ) {
        let currentFilters = JSON.parse(
          decodeURIComponent(escape(atob(currentPersistedState)))
        );
        let previousFilters = JSON.parse(
          decodeURIComponent(escape(atob(previousPersistedState)))
        );
        currentFilters = {
          ...currentFilters,
          ...currentFilters.appliedFiltersGroups,
          appliedFiltersGroups: undefined,
        };
        previousFilters = {
          ...previousFilters,
          ...previousFilters.appliedFiltersGroups,
          appliedFiltersGroups: undefined,
        };

        const appliedFilters = getObjectDiff(previousFilters, currentFilters);
        const recruitmentSearchFlow = AnalyticsService.getOrCreateFlow(
          FLOW_NAMES.RECRUITMENT_SEARCH
        );
        Object.entries(appliedFilters).forEach(([path, filter]) => {
          AnalyticsService.dispatchEvent(eventNames.RECRUITMENT_SEARCH_DETAIL, {
            flow_id: recruitmentSearchFlow.flow_id,
            screen_presentation_id:
              recruitmentSearchFlow.screen_presentation_id,
            recruitment_search_id: sessionId || '',
            recruitment_screen_name: getAnalyticsSearchScreenName(viewType),
            search_detail_id: path,
            search_detail_name: path,
            search_detail_value:
              typeof filter !== 'string' ? JSON.stringify(filter) : filter,
            campaign_id: '',
          });
        });
      }
    } catch {
      /* empty */
    }
  }, [searchParams, previousSearchParams]);

  // Persisting state to search params on relevant changes
  useEffect(() => {
    const base64String = encodePersistedState(
      viewType,
      searchQuery,
      filtersGroupsSnapshot,
      sorting,
      brandId,
      selectedCampaignId
    );
    searchParams.set(PERSISTED_STATE_KEY, base64String);
    setSearchParams(searchParams, {replace: true});
  }, [
    viewType,
    filtersGroupsSnapshot,
    searchQuery,
    sorting,
    brandId,
    selectedCampaignId,
  ]);

  useEffect(() => {
    if (isMetadataFetched && metadata) {
      searchCreatorsActions.setFiltersOptionsFromMetadata(metadata);
    }
  }, [isMetadataFetched, isMetadataError, metadata]);

  useEffect(() => {
    if (
      sortedCampaigns.length > 0 &&
      (!wasAlreadyAutoSelected || !selectedCampaignId)
    ) {
      searchCreatorsActions.updateUserLastBrandId(brandId);
      setCampaignId(sortedCampaigns[0]?.id);
      searchCreatorsActions.updateUserSelectedCampaignId(
        sortedCampaigns[0]?.id
      );
      sessionStorage.setItem(AUTO_SELECT_CAMPAIGN_SESSION_KEY, 'true');
    }
  }, [sortedCampaigns, wasAlreadyAutoSelected]);

  useEffect(() => {
    if (searchParams.get('creatorProfileIdRedirect')) {
      campaignFiltersAppliedFlashMessageRef.current?.();
    }
  }, [creatorProfileIdRedirect]);

  useEffect(() => {
    const fetchInvitationStatuses = async () => {
      if (selectedCampaignId) {
        try {
          setCreatorsInvitedStatus([]);
          const invitedStatuses =
            await creatorsFetchers.checkInvitationStatusBatch({
              campaignId: selectedCampaignId,
            });
          setCreatorsInvitedStatus(invitedStatuses);
        } catch (error) {
          console.error('Error fetching invitation statuses', error);
          setCreatorsInvitedStatus([]);
        }
      } else {
        setCreatorsInvitedStatus([]);
      }
    };

    fetchInvitationStatuses();
  }, [selectedCampaignId]);

  function raiseSuccessCampaignFiltersSelected() {
    campaignFiltersAppliedFlashMessageRef.current?.();
    campaignFiltersAppliedFlashMessageRef.current = raiseFlashMessage({
      status: 'error',
      icon: (
        <Icon
          size="large"
          appearance="brand"
          name="StateBar-Effects"
          color="#29C893"
        />
      ),
      mode: 'light',
      allowOnlySingleAppearance: true,
      message: (
        <FlashMessage.Banner
          title={translate(`${TRANSLATION_PREFIX}.auto-filters.title`)}
          subtitle={translate(`${TRANSLATION_PREFIX}.auto-filters.content`)}
        />
      ),
    });
  }

  function raiseEmailCopiedMessage() {
    raiseFlashMessage({
      status: 'success',
      mode: 'light',
      message: (
        <Headline size="xs" width="100%" display="flex" justifyContent="center">
          {translate(`${TRANSLATION_PREFIX}.email-copied`)}
        </Headline>
      ),
      withCloseButton: false,
      style: {justifyContent: 'center'},
    });
  }

  /* ====== Event handlers ====== */

  /* Campaign Selector events handler */
  const onCampaignChange = (chosenCampaignId: string | string[]) => {
    if (chosenCampaignId === selectedCampaignId) {
      return;
    }
    setUniqueSelectActionTrigger(uuid());
    setCampaignId(chosenCampaignId as string);
    searchCreatorsActions.updateUserSelectedCampaignId(
      chosenCampaignId as string
    );
  };

  /* Creator Action Modal events handlers */
  const onCloseCreatorAction = (
    reason: 'success' | 'cancelled',
    creators?: CreatorProps[],
    actionName?: string,
    result?: unknown
  ) => {
    if (reason === 'success') {
      if (
        activeActionKeyAndCreators?.actionKey ===
        CREATOR_ACTION_KEYS.ADD_TO_MY_CREATORS
      ) {
        raiseCreatorAddedToListSuccess(
          (result as MembershipLabel[]).filter(
            (label: MembershipLabel & {disabled?: boolean}) => !label.disabled
          )
        );
      }
      if (getSingleCreatorProfileId()) {
        queryClient.invalidateQueries([
          'creatorProfile',
          false,
          getSingleCreatorProfileId(),
          brandId,
          'current_brand_membership.labels',
        ]);
        queryClient.invalidateQueries([
          'creatorProfile',
          false,
          getSingleCreatorProfileId(),
          brandId,
        ]);
      }
      searchCreatorsActions.clearSelectedCreators();
    }
    trackSearchButtonEvent.pressed({
      button_name: reason,
      screen_name: getAnalyticsSearchScreenName(viewType),
      tab: activeActionKeyAndCreators?.actionKey || '',
    });
    trackRecruitmentActionFlowEvent.ended({
      action_id: actionName || '',
      screen_name: getAnalyticsSearchScreenName(viewType),
      recruitment_search_id: sessionId || '',
      creator_id: JSON.stringify(
        creators ? creators.map((creator) => creator.id) : []
      ),
      reason,
      source: getAnalyticsSearchScreenName(viewType),
    });
    setActiveActionKeyAndCreators(null);
  };

  const handlePresentedOrDismissed = (
    presentedOrDismissed: 'presented' | 'dismissed',
    name: string,
    creators?: CreatorProps[]
  ) => {
    onPresentedOrDismissed(presentedOrDismissed, name);
    if (presentedOrDismissed === 'presented') {
      trackRecruitmentActionFlowEvent.started({
        action_name: name,
        screen_name: getAnalyticsSearchScreenName(viewType),
        recruitment_search_id: sessionId || '',
        creator_id: JSON.stringify(
          creators ? creators.map((creator) => creator.id) : []
        ),
        source: getAnalyticsSearchScreenName(viewType),
      });
    }
  };

  /* NoActiveCampaignsModal events handlers */
  const onCloseNoActiveCampaignsModal = () => {
    setShowNoActiveCampaignsModal(false);
    trackSearchButtonEvent.pressed({
      button_name: 'dismiss',
      screen_name: getAnalyticsSearchScreenName(viewType),
      tab: 'no-active-campaigns',
    });
  };

  const onSaveToListClick = () => {
    setShowNoActiveCampaignsModal(false);
    setActiveActionKeyAndCreators({
      actionKey: CREATOR_ACTION_KEYS.ADD_TO_MY_CREATORS,
      creators: mapCreatorSearchResultsForAction([
        ...selectedCreators.values(),
      ]),
    });
    trackSearchButtonEvent.pressed({
      button_name: translate(
        `${TRANSLATION_PREFIX}.components.no-active-campaigns-modal.save-to-list-button`
      ),
      screen_name: getAnalyticsSearchScreenName(viewType),
      tab: 'no-active-campaigns',
    });
  };

  const onCreateCampaignClick = () => {
    setShowNoActiveCampaignsModal(false);
    searchParams.set('showNewCampaignWizardModal', 'true');
    setSearchParams(searchParams);

    trackSearchButtonEvent.pressed({
      button_name: translate(
        `${TRANSLATION_PREFIX}.components.no-active-campaigns-modal.create-campaign-button`
      ),
      screen_name: getAnalyticsSearchScreenName(viewType),
      tab: 'no-active-campaigns',
    });
  };

  /* Filters drawer events handlers */
  const onActionDrawerButtonClick = (label: string) => {
    trackSearchButtonEvent.pressed({
      button_name: label,
      screen_name: getAnalyticsSearchScreenName(viewType),
    });
  };

  /* Search input events handlers */
  const onSubmitSearchInput = (value: string) => {
    if (searchQuery !== value) {
      searchCreatorsActions.updateSearchQuery(value);
      if (
        searchQuery &&
        !value &&
        sorting?.field !== DefaultSortField &&
        sorting?.field === SortField.relevancy
      ) {
        // if the user is clearing the search query AND the current sorting field is "Best Match" =>
        //  reset the sorting field to the default (Rating)
        searchCreatorsActions.changeSorting(DefaultSortField);
      }
    }
  };

  /* SearchCreatorsDisplay event handlers */
  const onGridCreatorCardCtaClick = (
    actionKeyAndCreators: ActiveActionKeyAndCreators
  ) => {
    onAddToMyCreatorsAction(actionKeyAndCreators.creators);
  };

  const onTableCreatorsSelection = useCallback(
    (creators: CreatorSearchResult[], isSelected: boolean) => {
      trackSearchButtonEvent.pressed({
        button_name: isSelected ? 'Select creators' : 'Deselect creators',
        screen_name: getAnalyticsSearchScreenName(viewType),
        creator_id: JSON.stringify(mapCreatorsForAnalytics(creators)),
      });
    },
    [trackSearchButtonEvent]
  );

  const onTableSortingSelection = useCallback(
    (sortField: string, direction: string) => {
      trackSearchButtonEvent.pressed({
        button_name: `Sorting: ${sortField || 'default'}`,
        screen_name: getAnalyticsSearchScreenName(viewType),
        tab: direction,
      });
    },
    [trackSearchButtonEvent]
  );

  /* Creators Action Bar event handlers */
  const renderCreatorsActionBar = () => (
    <CreatorsActionsBar
      avatarUrls={[...selectedCreators].map(([, creator]) =>
        getProfileImageUrl(creator.profileImage)
      )}
      actionItems={tableActionsBarItems}
      closeButtonOnClick={() => {
        searchCreatorsActions.clearSelectedCreators();
        trackSearchButtonEvent.pressed({
          button_name: translate(
            `${TRANSLATION_PREFIX}.components.creators-actions-bar.button-clear-all`
          ),
          screen_name: getAnalyticsSearchScreenName(viewType),
        });
      }}
      closeButtonText={translate(
        `${TRANSLATION_PREFIX}.components.creators-actions-bar.button-clear-all`
      )}
      closeButtonProps={{
        appearance: 'neutral',
        mode: 'plain',
        size: 'medium',
      }}
      onPresentedOrDismissed={onPresentedOrDismissed}
    />
  );

  const actionsBarWidthCalculation = (width: number | undefined) =>
    width
      ? Math.min(width - POPPER_HORIZONTAL_PADDING * 2, POPPER_MAX_WIDTH)
      : 'auto';

  const onAddToMyCreatorsAction = useCallback(
    (creators: CreatorProps[]) => {
      setActiveActionKeyAndCreators({
        actionKey: CREATOR_ACTION_KEYS.ADD_TO_MY_CREATORS,
        creators,
      });

      trackSearchButtonEvent.pressed({
        button_name: translate(
          `${TRANSLATION_PREFIX}.components.creator-action.add-to-my-creators.tooltip`
        ),
        screen_name: getAnalyticsSearchScreenName(viewType),
        tab: '',
      });
    },
    [trackSearchButtonEvent]
  );

  const inviteCreatorsToCampaign = useInviteCreatorsToCampaign();

  const onAddToSelectedCampaignAction = useCallback(
    async (creators: CreatorProps[]) => {
      if (brandCampaigns.length === 0) {
        setShowNoActiveCampaignsModal(true);
      } else {
        try {
          const creatorsIds: string[] = creators.map((creator) => creator.id);
          const result = await inviteCreatorsToCampaign.invite({
            creatorIds: creatorsIds,
            campaignId: selectedCampaignId ?? '',
          });
          if (result) {
            setCreatorsInvitedStatus((prevState) =>
              prevState.concat(creatorsIds.map((id) => ({id, invited: true})))
            );
            raiseCreatorInvitedToCampaignSuccess(creatorsIds.length);
          }
        } catch {
          raiseFlashMessageError();
        }
      }
      trackSearchButtonEvent.pressed({
        button_name:
          brandCampaigns.length === 0
            ? translate(
                `${TRANSLATION_PREFIX}.components.no-active-campaigns-modal.save-to-list-button`
              )
            : translate(
                `${TRANSLATION_PREFIX}.components.creator-action.add-to-campaign.tooltip`
              ),
        screen_name: getAnalyticsSearchScreenName(viewType),
        tab: '',
      });
    },
    [selectedCampaignId, brandCampaigns.length, trackSearchButtonEvent]
  );

  const onAddToCampaignAction = useCallback(
    (creators: CreatorProps[]) => {
      if (brandCampaigns.length === 0) {
        setShowNoActiveCampaignsModal(true);
      } else {
        setActiveActionKeyAndCreators({
          actionKey: CREATOR_ACTION_KEYS.ADD_TO_CAMPAIGN,
          creators,
        });
      }
      trackSearchButtonEvent.pressed({
        button_name:
          brandCampaigns.length === 0
            ? translate(
                `${TRANSLATION_PREFIX}.components.no-active-campaigns-modal.save-to-list-button`
              )
            : translate(
                `${TRANSLATION_PREFIX}.components.creator-action.add-to-campaign.tooltip`
              ),
        screen_name: getAnalyticsSearchScreenName(viewType),
        tab: '',
      });
    },
    [brandCampaigns.length, trackSearchButtonEvent]
  );

  const onStartThreadAction = useCallback(
    (creators: CreatorProps[]) => {
      setActiveActionKeyAndCreators({
        actionKey: CREATOR_ACTION_KEYS.MESSAGE_CREATOR,
        creators,
      });
      trackSearchButtonEvent.pressed({
        button_name: translate(
          `${TRANSLATION_PREFIX}.components.creator-action.message-creator.tooltip`
        ),
        screen_name: SCREEN_NAMES.SEARCH,
        tab: '',
      });
    },
    [trackSearchButtonEvent]
  );

  /* ====== Table Creators Actions Bar action items ====== */
  const tableActionsBarItems: ActionItem[] = useMemo(() => {
    return [
      {
        id: CREATOR_ACTION_KEYS.ADD_TO_MY_CREATORS,
        icon: 'Actions-Bookmark',
        tooltip: translate(
          `${TRANSLATION_PREFIX}.components.creator-action.add-to-my-creators.tooltip`
        ),
        onClick: () =>
          onAddToMyCreatorsAction(
            mapCreatorSearchResultsForAction([...selectedCreators.values()])
          ),
      },
      {
        id: CREATOR_ACTION_KEYS.ADD_TO_CAMPAIGN,
        icon: 'Navigation-Megaphone',
        tooltip: translate(
          `${TRANSLATION_PREFIX}.components.creator-action.add-to-campaign.tooltip`
        ),
        onClick: () =>
          onAddToCampaignAction(
            mapCreatorSearchResultsForAction([...selectedCreators.values()])
          ),
      },
    ];
  }, [selectedCreators, onAddToMyCreatorsAction, onAddToCampaignAction]);

  const getCreatorCardCtaProps = () => {
    const isCsm: boolean = dashboardUser.hasPlatformAdminRole;

    const getButtonText = (creator: CreatorCardProps) => {
      const creatorStatus = creatorsInvitedStatus.find(
        (status) => status.id === creator.id
      );

      if (isCsm) {
        if (creatorStatus && creatorStatus.invited) {
          return (
            <span className={styles.invitationSent}>
              <Icon name="Actions-Accept" size="medium" appearance="disabled" />
              {translate('components.creator-card.invitation-sent')}
            </span>
          );
        }
        return translate('components.creator-card.invite-to-campaign');
      }

      return translate('components.creator-card.add-to-list');
    };

    const isButtonDisabled = (creator: CreatorCardProps) => {
      const creatorStatus = creatorsInvitedStatus.find(
        (status) => status.id === creator.id
      );
      return isCsm && creatorStatus && creatorStatus.invited;
    };

    return {
      buttonText: (creator: CreatorCardProps) => getButtonText(creator),
      onClick: (event: React.MouseEvent, creator: CreatorCardProps) => {
        event.stopPropagation();
        event.preventDefault();

        if (isCsm) {
          onAddToSelectedCampaignAction([
            mapCreatorCardPropsForAction(creator),
          ]);
        } else {
          onAddToMyCreatorsAction([mapCreatorCardPropsForAction(creator)]);
        }
      },
      isDisabled: (creator: CreatorCardProps) => isButtonDisabled(creator),
    };
  };

  /* ====== Grid Creator Card Menu action items callBack ====== */
  const createCreatorCardOverflowMenuItems = useCallback(
    async (creator: CreatorCardProps): Promise<CreatorMenuItemProps[]> => {
      let conversation: {id: string} | undefined;
      if (canCreateCollaboration) {
        conversation =
          await conversationsFetcher.currentBrandCollaborationConversation({
            // Fetcher is built to work with react-query, so it expects a
            // QueryContext, but using custom hook will introduce complexity
            // overhead, so we're just passing the required params directly
            queryKey: [],
            meta: {brandId, creatorId: creator.id},
          });
      }

      return [
        {
          iconName: 'Navigation-Copy',
          title: translate('components.creator-card.overflow-menu.copy-email'),
          onClick: () => {
            copy(creator.email);
            raiseEmailCopiedMessage();
            trackSearchButtonEvent.pressed({
              button_name: translate(
                'components.creator-card.overflow-menu.copy-email'
              ),
              screen_name: getAnalyticsSearchScreenName(viewType),
              creator_id: creator.id,
            });
          },
        },
        {
          iconName: 'Actions-Bookmark',
          title: translate('components.creator-card.overflow-menu.add-to-list'),
          onClick: () => {
            onAddToMyCreatorsAction([mapCreatorCardPropsForAction(creator)]);
          },
        },
        canCreateCollaboration
          ? {
              iconName: 'Navigation-Messages',
              title: conversation
                ? translate('components.creator-card.overflow-menu.open-thread')
                : translate(
                    'components.creator-card.overflow-menu.start-thread'
                  ),
              onClick: () => {
                if (conversation) {
                  navigate(`/collaborations/${conversation.id}`);
                } else {
                  onStartThreadAction([mapCreatorCardPropsForAction(creator)]);
                }
              },
            }
          : null,
      ].filter(Boolean) as CreatorMenuItemProps[];
    },
    [
      canCreateCollaboration,
      navigate,
      onAddToMyCreatorsAction,
      onStartThreadAction,
      trackSearchButtonEvent,
      viewType,
    ]
  );

  return (
    <Page
      id="search"
      className={styles.container}
      childrenClassName={styles.content}
    >
      <SearchTopBar
        searchQuery={searchQuery}
        onSubmitSearchInput={onSubmitSearchInput}
        titleProps={{size: sortedCampaigns.length ? 'xxs' : 'lg'}}
      />
      <div className={styles.actionHeader}>
        <CampaignSelector
          brandId={brandId}
          selectedCampaignId={selectedCampaignId}
          campaigns={sortedCampaigns}
          onCampaignChange={onCampaignChange}
        />
        <SearchControls
          totalResults={paging.totalResults}
          searchQuery={searchQuery}
          viewType={viewType}
          onActionDrawerButtonClick={onActionDrawerButtonClick}
          sorting={sorting}
          selectedPlatform={selectedPlatform}
        />
      </div>
      <ActionsDrawer
        onClose={(label: string) => {
          searchCreatorsActions.closeFiltersDrawer();
          logActionDrawerButtonClick(label);
        }}
        onApply={(label: string) => {
          searchCreatorsActions.applyFiltersGroup();
          logActionDrawerButtonClick(label);
        }}
        onClearAll={(label: string) => {
          searchCreatorsActions.resetFiltersGroup();
          logActionDrawerButtonClick(label);
        }}
        onPresentedOrDismissed={onPresentedOrDismissed}
      />
      <CreatorAction
        selectedCampaignId={selectedCampaignId}
        creators={activeActionKeyAndCreators?.creators || []}
        activeCreatorAction={activeActionKeyAndCreators?.actionKey || null}
        close={onCloseCreatorAction}
        onPresentedOrDismissed={handlePresentedOrDismissed}
        initState={getCreatorActionInitState()}
      />
      <NoActiveCampaignsModal
        open={showNoActiveCampaignsModal}
        close={onCloseNoActiveCampaignsModal}
        onSaveToListClick={onSaveToListClick}
        onCreateCampaignClick={onCreateCampaignClick}
      />
      <FloatingPopperBar
        isOpen={selectedCreators.size > 0 && viewType === VIEW_TYPE_TABLE}
        placement="bottom"
        widthCalculation={actionsBarWidthCalculation}
        placementMargin={POPPER_PLACEMENT_OFFSET}
        renderContent={renderCreatorsActionBar}
      >
        <SearchCreatorsDisplay
          viewType={viewType}
          isRestoringFromPersistence={isRestoringFromPersistenceState}
          searchCreatorsQueryDisabled={isLoadingPrefetchedData}
          onCreatorsSelection={onTableCreatorsSelection}
          onSortingSelection={onTableSortingSelection}
          creatorCardOverflowMenuEnabled={dashboardUser.hasPlatformAdminRole}
          creatorCardOverflowMenuItemsCallBack={
            createCreatorCardOverflowMenuItems
          }
          creatorCardCtaProps={getCreatorCardCtaProps()}
        />
      </FloatingPopperBar>
    </Page>
  );
}

export default Search;
