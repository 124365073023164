import {useFlags} from 'launchdarkly-react-client-sdk';
import React, {useState, useEffect, useCallback} from 'react';
import {useSearchParams} from 'react-router-dom';
import {v4 as uuid} from 'uuid';
import {Button, Icon} from '@lightricks/react-design-system';
import AnalyticsService, {
  eventNames,
} from '@/services/analytics/AnalyticsService';
import {FLOW_NAMES} from '@/lib/analytics/analyticsConstants';
import translate from '@/utils/translate';
import {ROUTES} from '@/config/routesConstants';
import {BriefAiWizardFormProps} from '@/views/campaigns/brief/components/brief-wizard/BriefAiWizardProps';
import {
  BRAND_AWARENESS_CAMPAIGN_STEPS,
  CONTENT_CREATION_CAMPAIGN_STEPS,
} from '@/views/campaigns/brief/components/brief-wizard/steps';
import Modal from '@/components/modal';
import CampaignTypeOptions, {
  CAMPAIGN_TYPE,
  CampaignTypes,
} from '@/components/new-campaign-wizard-modal/CampaignTypeOptions';
import NewCampaignWizard from '@/components/new-campaign-wizard-modal/NewCampaignWizard';
import useAgencyQuery from '@/hooks/queries/use-agency-query';
import useSubscriptionQuery from '@/hooks/queries/use-subscription-query';
import useBrandId from '@/hooks/use-brand-id';
import useIsMobile from '@/hooks/use-is-mobile';
import useNavigation from '@/hooks/use-navigation';
import useSubscriptionLevers from '@/hooks/use-subscription-levers';
import {
  NewCampaignFormData,
  useNewCampaignFormDataStore,
} from '@/contexts/NewCampaignContext';
import styles from './NewCampaignWizardModal.module.scss';

const DEFAULT_BUDGET = 2000;

const INITIAL_FORM_DATA = {
  brandName: '',
  budget: DEFAULT_BUDGET,
  customBudget: null,
  product: '',
  network: 'instagram',
  requiredDeliverables: [],
  creatorType: '',
  creatorCostRange: null,
  sendProductToCreators: false,
  sendProductToCreatorsMethod: null,
  sendProductToCreatorsCashValue: null,
  subscriptionType: null,
  genders: [],
  ageRange: null,
  locations: [],
  specialties: [],
};

export type NewCampaignWizardModalProps = {testID?: string};
function NewCampaignWizardModal(props: NewCampaignWizardModalProps) {
  const {testID} = props;
  const isMobile = useIsMobile();

  const [searchParams, setSearchParams] = useSearchParams();

  const [campaignType, setCampaignType] = useState<CampaignTypes>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState<BriefAiWizardFormProps>({
    ...INITIAL_FORM_DATA,
  });

  const {dispatch: newCampaignFormDataDispatch} = useNewCampaignFormDataStore();
  const navigation = useNavigation();
  const [generatedCampaignId, setGeneratedCampaignId] = useState(uuid());
  const brandId = useBrandId();
  const {currentBrand, allowEditCreatorBid} = useAgencyQuery({brandId});
  const {pricingUpdates} = useFlags();
  const {subscription} = useSubscriptionQuery({brandId});

  const levers = useSubscriptionLevers();

  const shouldShowLeverLimitModal =
    levers?.isMaxed('TotalCampaignCount') ||
    levers?.isMaxed('ActiveCampaignCount');

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      subscriptionType: subscription.subscriptionType,
    }));
  }, [subscription?.subscriptionType]);

  useEffect(() => {
    if (!pricingUpdates && shouldShowLeverLimitModal && isModalOpen) {
      searchParams.set('showLeverLimitModal', 'true');
      searchParams.delete('showNewCampaignWizardModal');
      setSearchParams(searchParams);
    }
  }, [shouldShowLeverLimitModal, isModalOpen]);

  useEffect(() => {
    if (searchParams.get('showNewCampaignContentModal') === 'true') {
      setCampaignType(CAMPAIGN_TYPE.CONTENT_CREATION);
    } else if (searchParams.get('showNewCampaignAwarenessModal') === 'true') {
      setCampaignType(CAMPAIGN_TYPE.BRAND_AWARENESS);
    } else {
      setCampaignType(null);
    }
    const showNewCampaignWizardModal =
      searchParams.get('showNewCampaignWizardModal') === 'true';
    setIsModalOpen(showNewCampaignWizardModal);
    const campaignCreationFlowExists = AnalyticsService.flowExists(
      FLOW_NAMES.CAMPAIGN_CREATION
    );
    if (!campaignCreationFlowExists && showNewCampaignWizardModal) {
      AnalyticsService.createFlow(FLOW_NAMES.CAMPAIGN_CREATION);
    }
  }, [searchParams]);

  useEffect(() => {
    setGeneratedCampaignId(uuid());
  }, [isModalOpen]);

  const resetForm = () => {
    setActiveStep(0);
    setFormData({...INITIAL_FORM_DATA});
  };

  const handleClose = useCallback(() => {
    searchParams.delete('showNewCampaignWizardModal');
    searchParams.delete('showNewCampaignContentModal');
    searchParams.delete('showNewCampaignAwarenessModal');
    setSearchParams(searchParams);
    resetForm();

    let currentStep;
    if (campaignType) {
      if (campaignType === CAMPAIGN_TYPE.BRAND_AWARENESS) {
        currentStep = BRAND_AWARENESS_CAMPAIGN_STEPS[activeStep].id;
      } else {
        currentStep = CONTENT_CREATION_CAMPAIGN_STEPS[activeStep].id;
      }
    } else {
      currentStep = 'select_campaign_type';
    }
    if (!isMobile) {
      const campaignCreationFlow = AnalyticsService.getOrCreateFlow(
        FLOW_NAMES.CAMPAIGN_CREATION
      );

      AnalyticsService.dispatchEvent(eventNames.CAMPAIGN_CREATION_FLOW_ENDED, {
        flow_id: campaignCreationFlow.flow_id,
        reason: 'abandoned',
        current_step: currentStep,
        campaign_creation_end_destination: 'closed_modal',
        campaign_details: JSON.stringify({
          campaign_type: campaignType,
          ...formData,
        }),
        error: '',
        campaign_id: generatedCampaignId,
      });

      AnalyticsService.endFlow(FLOW_NAMES.CAMPAIGN_CREATION);
    }
  }, [searchParams, setSearchParams, activeStep, formData, campaignType]);

  const onSelectCampaignType = (selectedCampaignType: CampaignTypes) => {
    const campaignCreationFlow = AnalyticsService.getOrCreateFlow(
      FLOW_NAMES.CAMPAIGN_CREATION
    );

    AnalyticsService.dispatchEvent(eventNames.CAMPAIGN_CREATION_TRANSITION, {
      flow_id: campaignCreationFlow.flow_id,
      current_step: 'select_campaign_type',
      destination_step:
        selectedCampaignType === CAMPAIGN_TYPE.BRAND_AWARENESS
          ? BRAND_AWARENESS_CAMPAIGN_STEPS[0].id
          : CONTENT_CREATION_CAMPAIGN_STEPS[0].id,
      step_details: JSON.stringify({
        campaign_type: selectedCampaignType,
      }),
      step_title: translate('components.modal-new-campaign-wizard.title'),
      campaign_id: generatedCampaignId,
      step_index: 0,
    });
    setCampaignType(selectedCampaignType);
  };

  const onFormSubmit = (submittedFormData: NewCampaignFormData) => {
    newCampaignFormDataDispatch({
      type: 'SET_NEW_CAMPAIGN_FORM_DATA',
      formData: submittedFormData,
    });

    navigation.navigate(
      `/new-campaigns/${generatedCampaignId}/brief/edit?brandId=${brandId}`
    );
    const activeCampaignSteps =
      campaignType === CAMPAIGN_TYPE.BRAND_AWARENESS
        ? BRAND_AWARENESS_CAMPAIGN_STEPS
        : CONTENT_CREATION_CAMPAIGN_STEPS;

    const campaignCreationFlow = AnalyticsService.getOrCreateFlow(
      FLOW_NAMES.CAMPAIGN_CREATION
    );

    AnalyticsService.dispatchEvent(eventNames.CAMPAIGN_CREATION_TRANSITION, {
      flow_id: campaignCreationFlow.flow_id,
      current_step: activeCampaignSteps[activeStep].id,
      destination_step: 'draft_review',
      step_details: JSON.stringify({
        campaign_type: campaignType,
        ...formData,
      }),
      step_title: activeCampaignSteps[activeStep].id,
      campaign_id: generatedCampaignId,
      step_index: activeStep + 1,
    });

    resetForm();
  };

  useEffect(() => {
    if (isModalOpen && isMobile) {
      handleClose();
      navigation.navigate(ROUTES.MOBILE_NOT_SUPPORTED);
    }
  }, [searchParams, isModalOpen, isMobile]);

  const modalContent = {
    default: <CampaignTypeOptions setCampaignType={onSelectCampaignType} />,
    wizard: (
      <NewCampaignWizard
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        formData={formData}
        setFormData={setFormData}
        campaignId={generatedCampaignId}
        campaignType={campaignType}
        brandName={currentBrand?.name}
        allowEditCreatorBid={allowEditCreatorBid}
        onSubmit={onFormSubmit}
      />
    ),
  };

  if (shouldShowLeverLimitModal && !pricingUpdates) {
    return null;
  }

  return (
    <Modal
      className={`${styles.modal} ${
        campaignType ? styles.wizardContainer : styles.campaignTypeContainer
      }`}
      contentClassName={`${campaignType ? styles.modalContent : ''} ${
        campaignType ? styles[`modalContent_${campaignType}`] : ''
      }`}
      open={isModalOpen && !isMobile}
      onClose={!campaignType ? handleClose : undefined}
    >
      <>
        <Button
          appearance="neutral"
          mode="plain"
          size="small"
          className={styles.closeButton}
          onClick={handleClose}
          icon={
            <Icon
              size="medium"
              appearance="neutral"
              name="Actions-Close-Small"
            />
          }
        />
        <div className={styles.wizardContent}>
          {campaignType ? modalContent.wizard : modalContent.default}
        </div>
      </>
    </Modal>
  );
}

export default NewCampaignWizardModal;
